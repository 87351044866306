import axiosInstance from "@/axios";

export default {
    getJobs() {
        console.debug("ManualFactory getJobs");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/manual")
                .then(response => {
                    console.debug("getJobs response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getJobs error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    submitLinks(requestBody) {
        console.debug("ManualFactory submitLinks");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/job/manual", requestBody)
                .then(response => {
                    console.debug("submitLinks response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("submitLinks error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },
}
