import {Store} from 'vuex'
import {createStore} from 'vuex-extensions'

const store = createStore(Store, {
    state () {
        return {
            loggedIn: false,
            needLogout: false,
            csrfToken: "",
            userinfo: {},

            activeAssets: [],
            inactiveAssets: [],

            theme: 'light'
        }
    },
    getters: {
        isLoggedIn (state) {
            return state.loggedIn;
        },
        needLogout (state) {
            return state.needLogout;
        },
        isStaff (state) {
            return state.userinfo.companyname === "Cyber Theft Watch"
        },
        getCSRFToken (state) {
            return state.csrfToken;
        },
        getUserInfo (state) {
            return state.userinfo;
        },
        getAllAssetData (state) {
            return state.activeAssets.concat(state.inactiveAssets);
        },
        getActiveAssetData (state) {
            return state.activeAssets;
        },
        getInactiveAssetData (state) {
            return state.inactiveAssets;
        },
        hasAssets (state) {
            return state.activeAssets.length > 0 || state.inactiveAssets.length > 0;
        },
        getUserRoles (state) {
            return state.userinfo.roles;
        },


    },
    mutations: {
        initialiseStore(state) {
            // Check if the ID exists
            if(localStorage.getItem('cybertheftwatch')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('cybertheftwatch')))
                );
            }
        },
        logoutNow (state) {
            state.needLogout = true;
        },
        setAssets (state, assets) {
            console.debug(assets)
            state.activeAssets = assets.active;
            state.activeAssets = assets.active.sort((a,b)=> (a.name > b.name ? 1 : -1))
            state.inactiveAssets = assets.inactive;
            state.inactiveAssets = assets.inactive.sort((a,b)=> (a.name > b.name ? 1 : -1))
        },
        setLoggedIn (state, csrfToken) {
            state.loggedIn = true;
            state.csrfToken = csrfToken;
        },
        setUserInfo (state, payload) {
            state.userinfo = payload;
        },
        setTheme (state, payload) {
            state.theme = payload;
        }
    },

    modules: {
        sub: {
            actions: {
                logout() {
                    this.reset()
                    localStorage.clear()
                }
            }
        }
    }
})

store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('cybertheftwatch', JSON.stringify(state));
});
export default store;
