<template>
    <router-view/>
</template>

<script>
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
polyfillCountryFlagEmojis();

document.documentElement.style.fontSize = "12px"

export default {
    mounted() {
        // console.debug("Checking cookies", this.$cookies.get("cookieConsent"), this.$cookies.get("preferences"));

        if (this.$cookies.get("cookieConsent") === null) {
            console.log("Setting up!")
            this.$cookies.set("cookieConsent", {
                "general": false,
                "preferences": false,
                "statistics": false,
                "marketing": false,
            }, "30d");
        }

        if (this.$cookies.get("preferences") === null) {
            this.$cookies.set("preferences", this.$preferences.data, "30d");
        } else {
            this.initPreferences();
        }
    },

    methods: {
        initPreferences() {
            this.$preferences.init(this.$cookies.get("preferences"));
        }
    },

    watch: {
        "$preferences.data": {
            deep: true,
            immediate: true,
            handler: function (val) {
                // only update the cookie if the user has allowed it
                this.$preferences.data = val;
                if (this.$cookies.get("cookieConsent") !== null) {
                    if (this.$cookies.get("cookieConsent").preferences === true && this.$preferences.loaded === true) {
                        this.$cookies.set("preferences", val, "30d");
                    }
                }
            },
        }
    }
}
</script>

<style>
.emoji-flag {
    font-family: "Twemoji Country Flags", serif;
}
</style>
