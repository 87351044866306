import axiosInstance from "@/axios";

export default {
    getSearches(lazyParams) {
        console.debug("SearchPageFactory getSearches");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/job/searches", lazyParams)
                .then(response => {
                    console.debug("getSearches response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSearches error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSearchZip(searchIds, clientName, account) {
        console.debug("SearchPageFactory getSearchZip");
        const params = new URLSearchParams();
        searchIds.forEach((value) => {
            params.append('sid', value);
        });

        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/export/zip", {
                params: params,
                responseType: 'blob',
            })
                .then(response => {
                    console.debug("getSearchZip response: ", response);

                    // Create a Blob with the text data
                    const blob = new Blob([response.data], { type: 'application/x-zip-compressed' });

                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = 'ctw' + account.toString() + '-' + clientName + '.zip';

                    // Simulate a click on the download link
                    downloadLink.click();

                    // Cleanup the URL.createObjectURL() resource
                    URL.revokeObjectURL(downloadLink.href);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    this.$emitter.emit("bad-toast", "Error downloading zip file, likely there was no content to download.");
                    console.error("getSearchZip error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSearchTxt(searchIds, clientName, account) {
        console.debug("SearchPageFactory getSearchTxt");
        const params = new URLSearchParams();
        searchIds.forEach((value) => {
            params.append('sid', value);
        });

        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/export/txt", {
                params: params,
                responseType: 'blob',
            })
                .then(response => {
                    console.debug("getSearchTxt response: ", response);

                    // Create a Blob with the text data
                    const blob = new Blob([response.data], { type: 'text/plain' });

                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = 'ctw' + account.toString() + '-' + clientName + '.txt';

                    // Simulate a click on the download link
                    downloadLink.click();

                    // Cleanup the URL.createObjectURL() resource
                    URL.revokeObjectURL(downloadLink.href);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    this.$emitter.emit("bad-toast", "Error downloading txt file, likely there was no content to download.");
                    console.error("getSearchTxt error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSearchTxtFiltered(assets, clientName, types, account) {
        console.debug("SearchPageFactory getSearchTxtFiltered");
        const params = new URLSearchParams();
        if (assets.length !== 0) {
            assets.forEach((value) => {
                params.append('asset', value);
            });
        }
        if (types.length !== 0) {
            types.forEach((value) => {
                params.append('type', value);
            });
        }
        if (clientName !== null) {
            params.append('client', clientName);
        }

        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/export/txt", {
                params: params,
            })
                .then(response => {
                    console.debug("getSearchTxtFiltered response: ", response);

                    // Create a Blob with the text data
                    const blob = new Blob([response.data], { type: 'text/plain' });

                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = 'ctw' + account.toString() + '-' + clientName + '.txt';

                    // Simulate a click on the download link
                    downloadLink.click();

                    // Cleanup the URL.createObjectURL() resource
                    URL.revokeObjectURL(downloadLink.href);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSearchTxtFiltered error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getClients() {
        console.debug("SearchPageFactory getClients");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/client/names")
                .then(response => {
                    console.debug("getClients response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getClients error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getTypes() {
        console.debug("SearchPageFactory getTypes");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/types")
                .then(response => {
                    console.debug("getTypes response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTypes error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSearchMetrics(searchId) {
        console.debug("SearchPageFactory getSearchMetrics");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/detail", {
                params: {
                    sid: searchId,
                },
            })
                .then(response => {
                    console.debug("getSearchMetrics response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSearchMetrics error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSearchProgress(searchIds) {
        console.debug("SearchPageFactory getSearchProgress");
        const params = new URLSearchParams();
        searchIds.forEach((value) => {
            params.append('sid', value);
        });

        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/progress", {
                params: params,
            })
                .then(response => {
                    console.debug("getSearchProgress response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSearchProgress error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getAllAssetLinks(sid) {
        console.debug("SearchPageFactory getAllAssetLinks");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/links", {
                params: {
                    sid: sid,
                },
            })
                .then(response => {
                    console.debug("getAllAssetLinks response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAllAssetLinks error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getMultiAllAssetLinks(sids) {
        console.debug("SearchPageFactory getMultiAllAssetLinks");
        const params = new URLSearchParams();
        sids.forEach((value) => {
            params.append('sid', value);
        });

        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/multilinks", {
                params: params,
            })
                .then(response => {
                    console.debug("getMultiAllAssetLinks response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getMultiAllAssetLinks error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getPotentialFalseNegatives(sid) {
        console.debug("SearchPageFactory getPotentialFalseNegatives");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/job/searches/potentialfalsenegatives", {
                params: {
                    sid: sid,
                },
            })
                .then(response => {
                    console.debug("getPotentialFalseNegatives response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getPotentialFalseNegatives error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    putDynamicFilteringContent(dynamicFilteringContent) {
        console.debug("SearchPageFactory putDynamicFilteringContent");
        return new Promise((resolve, reject) => {
            axiosInstance.put("/job/dynamic", dynamicFilteringContent)
                .then(response => {
                    console.debug("putDynamicFilteringContent response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("putDynamicFilteringContent error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },
};
