import axiosInstance from "@/axios";

export default {
    getClientsAndLogos() {
        console.debug("ClientFactory getClientsAndLogos");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/client/info")
                .then(response => {
                    console.debug("getClientsAndLogos response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getClientsAndLogos error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    createClient(clientInfo) {
        console.debug("ClientFactory createClient");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/client/new", clientInfo)
                .then(response => {
                    console.debug("createClient response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("createClient error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    updateClient(clientInfo) {
        console.debug("ClientFactory updateClient");
        return new Promise((resolve, reject) => {
            axiosInstance.put("/client/update", clientInfo)
                .then(response => {
                    console.debug("updateClient response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("updateClient error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },
}
