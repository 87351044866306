import axiosInstance from "@/axios";

export default {
    getAllCaseInfo(query) {
        console.debug("CaseFactory getAllCaseInfo");
        return new Promise((resolve, reject) => {
            // Put query in body
            axiosInstance.post("/cases/all", query)
                .then(response => {
                    console.debug("getAllCaseInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAllCaseInfo error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getCaseImages(caseId) {
        console.debug("CaseFactory getCaseImages");
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/cases/${caseId}/images`)
                .then(response => {
                    console.debug("getCaseImages response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getCaseImages error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getCaseListing(caseId) {
        console.debug("CaseFactory getCaseListing");
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/cases/${caseId}/listing`)
                .then(response => {
                    console.debug("getCaseListing response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getCaseListing error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getCaseStory(caseId) {
        console.debug("CaseFactory getCaseStory");
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/cases/${caseId}/story`)
                .then(response => {
                    console.debug("getCaseStory response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getCaseStory error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getAssetImagesForCase(caseId) {
        console.debug("CaseFactory getAssetImagesForCase");
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/cases/${caseId}/assetImages`)
                .then(response => {
                    console.debug("getAssetImagesForCase response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAssetImagesForCase error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getCaseStatus(caseId) {
        console.debug("CaseFactory getCaseStatus");
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/cases/${caseId}/status`)
                .then(response => {
                    console.debug("getCaseStatus response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getCaseStatus error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    updateCaseListing(caseId, listing) {
        console.debug("CaseFactory updateCaseListing");
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/cases/${caseId}/listing`, listing)
                .then(response => {
                    console.debug("updateCaseListing response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("updateCaseListing error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    uploadCaseImages(caseId, images, selectedType, listing_id) {
        console.debug("CaseFactory uploadCaseImages")
        const formData = new FormData();
        images.files.forEach(file => {
            console.debug(file);
            formData.append("images", file);
        })
        formData.append("image_type", selectedType);
        formData.append("listing_id", listing_id);
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/cases/${caseId}/images`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(response => {
                    console.debug("uploadCaseImages response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("uploadCaseImages error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    addCaseStory(caseId, message_type, message) {
        console.debug("CaseFactory addCaseStory");
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/cases/${caseId}/story`, { message_type: message_type, message: message })
                .then(response => {
                    console.debug("addCaseStory response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("addCaseStory error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    escalateCase(caseId) {
        console.debug("CaseFactory escalateCase");
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/cases/${caseId}/escalate`)
                .then(response => {
                    console.debug("escalateCase response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("escalateCase error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    closeCase(caseId) {
        console.debug("CaseFactory closeCase");
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/cases/${caseId}/close`)
                .then(response => {
                    console.debug("closeCase response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("closeCase error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    retryCase(caseId) {
        console.debug("CaseFactory retryCase");
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/cases/${caseId}/retry`)
                .then(response => {
                    console.debug("retryCase response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("retryCase error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getCaseFilters() {
        console.debug("CaseFactory getCaseFilters");
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/cases/filters`)
                .then(response => {
                    console.debug("getCaseFilters response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getCaseFilters error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }

}
