import axiosInstance from "@/axios";

export default {
    getSusDomainData(username) {
        console.debug("DatareviewFactory getSusDomainData")
        let params = new URLSearchParams();
        params.append("username", username);
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/review/getassetdomains?${params.toString()}`)
                .then(response => {
                    console.debug("getSusDomainData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSusDomainData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    submitNewSusDomains(domainData) {
        console.debug("DatareviewFactory submitNewSusDomains")
        return new Promise((resolve, reject) => {
            axiosInstance.post("/review/setsusdomains", domainData)
                .then(response => {
                    console.debug("submitNewSusDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("submitNewSusDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getSusDomainSubmitters() {
        console.debug("DatareviewFactory getSusDomainSubmitters")
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/getassetdomainsubmitters")
                .then(response => {
                    console.debug("getSusDomainSubmitters response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSusDomainSubmitters error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getNewDomainForPreinject(preselectType) {
        console.debug("DatareviewFactory getNewDomainForPreinject");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/preinject/new?specific_type=" + preselectType)
                .then(response => {
                    console.debug("getNewDomainForPreinject response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getNewDomainForPreinject error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    markDomainAsDead(domainData) {
        console.debug("DatareviewFactory markDomainAsDead");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/review/preinject/dead", domainData)
                .then(response => {
                    console.debug("markDomainAsDead response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("markDomainAsDead error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    submitDomain(domainData) {
        console.debug("DatareviewFactory submitDomain");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/review/preinject/alive", domainData)
                .then(response => {
                    console.debug("submitDomain response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("submitDomain error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getStatsForUserAddedDomains() {
        console.debug("DatareviewFactory getStatsForUserAddedDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/preinject/stats")
                .then(response => {
                    console.debug("getStatsForUserAddedDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getStatsForUserAddedDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getCyberlockerDomains() {
        console.debug("DatareviewFactory getCyberlockerDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/cyberlocker/domains")
                .then(response => {
                    console.debug("getCyberlockerDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getCyberlockerDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    putCyberlockerDomains(submissionBody) {
        console.debug("DatareviewFactory putCyberlockerDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.put("/review/cyberlocker/domains", submissionBody)
                .then(response => {
                    console.debug("putCyberlockerDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("putCyberlockerDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getDirectDownloadDomains(aid) {
        console.debug("DatareviewFactory getDirectDownloadDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/directdownload/domains?aid=" + aid)
                .then(response => {
                    console.debug("getDirectDownloadDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getDirectDownloadDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getPreinjectDomains() {
        console.debug("DatareviewFactory getPreinjectDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/preinject/domains")
                .then(response => {
                    console.debug("getPreinjectDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getPreinjectDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    putPreinjectDomains(submissionBody) {
        console.debug("DatareviewFactory putPreinjectDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.put("/review/preinject/domains", submissionBody)
                .then(response => {
                    console.debug("putPreinjectDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("putPreinjectDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    exportHardExclude() {
        console.debug("DatareviewFactory exportHardExclude");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/export/hardexclude")
                .then(response => {
                    console.debug("exportHardExclude response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("exportHardExclude error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    exportSusDomains() {
        console.debug("DatareviewFactory exportSusDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/export/suspiciousdomains")
                .then(response => {
                    console.debug("exportSusDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("exportSusDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    exportPreinjectDomains() {
        console.debug("DatareviewFactory exportPreinjectDomains");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/review/export/preinject")
                .then(response => {
                    console.debug("exportPreinjectDomains response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("exportPreinjectDomains error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    createPreinjectDomain(domain) {
        console.debug("DatareviewFactory createPreinjectDomain");
        let params = new URLSearchParams();
        params.append("domain", domain);
        return new Promise((resolve, reject) => {
            axiosInstance.post("/review/preinject/domain?" + params.toString())
                .then(response => {
                    console.debug("createPreinjectDomain response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("createPreinjectDomain error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }

}
