import axiosInstance from "@/axios";

export default {
    addNewBlog(blogInfo) {
        console.debug("BlogFactory addNewBlog");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/blog/new", blogInfo)
                .then(response => {
                    console.debug("addNewBlog response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("addNewBlog error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getBlogListFromSpaces() {
        console.debug("BlogFactory getBlogListFromSpaces")
        // let spaces_url = `https://ctw-website.ams3.cdn.digitaloceanspaces.com/?list-type=2&prefix=articles`;
        let spaces_url = `https://ctw-website.ams3.digitaloceanspaces.com/?list-type=2&prefix=articles&timestamp=${new Date().getTime()}`;

        let params = {
            method: 'GET'
        }

        return new Promise((resolve, reject) => {
            fetch(spaces_url, params)
                .then(response => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then(data => {
                    console.debug("getBlogListFromSpaces data: ", data)
                    let keys = data.getElementsByTagName('Contents');
                    let articles = [];
                    for (let key of keys) {
                        if (key.getElementsByTagName('Key')[0].childNodes[0].nodeValue === 'articles/') {
                            continue;
                        }
                        let article = {
                            title: key.getElementsByTagName('Key')[0].childNodes[0].nodeValue,
                            last_modified: key.getElementsByTagName('LastModified')[0].childNodes[0].nodeValue,
                            size: key.getElementsByTagName('Size')[0].childNodes[0].nodeValue,
                            etag: key.getElementsByTagName('ETag')[0].childNodes[0].nodeValue,
                        }
                        articles.push(article);
                    }
                    console.debug("getBlogListFromSpaces articles: ", articles);
                    resolve(articles);
                })
                .catch(error => {
                    console.error("getBlogListFromSpaces error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getBlogMetaDataFile(filename) {
        console.debug("BlogFactory getBlogMetaDataFile")
        let spaces_url = 'https://ctw-website.ams3.cdn.digitaloceanspaces.com/' + filename;
        return new Promise((resolve, reject) => {
            fetch(spaces_url)
                .then(response => response.json())
                .then(data => {
                    console.debug("getBlogMetaDataFile data: ", data)
                    resolve(data);
                })
                .catch(error => {
                    console.error("getBlogMetaDataFile error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

}
