import axiosInstance from "@/axios";

export default {
    getTotalOverview() {
        console.debug("AnalyticsFactory getTotalOverview");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/totaloverview")
                .then(response => {
                    console.debug("getTotalOverview response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTotalOverview error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getTotalPagesSeen() {
        console.debug("AnalyticsFactory getTotalPagesSeen");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/totalpagesseen")
                .then(response => {
                    console.debug("getTotalPagesSeen response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTotalPagesSeen error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getTotalPagesReported() {
        console.debug("AnalyticsFactory getTotalPagesReported");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/totalpagesreported")
                .then(response => {
                    console.debug("getTotalPagesReported response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTotalPagesReported error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    get60DaysActivity() {
        console.debug("AnalyticsFactory get60DaysActivity");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/everythinggraph60days")
                .then(response => {
                    console.debug("get60DaysActivity response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("get60DaysActivity error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getTorrentGlobeData() {
        console.debug("AnalyticsFactory getTorrentGlobeData");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/torrentglobe")
                .then(response => {
                    console.debug("getTorrentGlobeData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTorrentGlobeData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getTopLeaked() {
        console.debug("AnalyticsFactory getTopLeaked");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/topleaked")
                .then(response => {
                    console.debug("getTopLeaked response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTopLeaked error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getTorrentInfo() {
        console.debug("AnalyticsFactory getTorrentInfo");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/torrentinfo")
                .then(response => {
                    console.debug("getTorrentInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTorrentInfo error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getSessionInfo() {
        console.debug("AdminFactory getSessionInfo");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/session/info")
                .then(response => {
                    console.debug("getSessionInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSessionInfo error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getCyberlockerWeekData() {
        console.debug("AnalyticsFactory getCyberlockerWeekData");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cyberlocker/hourly")
                .then(response => {
                    console.debug("getCyberlockerWeekData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getCyberlockerWeekData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getPreinjectDomainInfo() {
        console.debug("AdminFactory getPreinjectDomainInfo");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/preinjectdomains")
                .then(response => {
                    console.debug("getPreinjectDomainInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getPreinjectDomainInfo error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getPriorityStaleJobs() {
        console.debug("AdminFactory getPriorityStaleJobs");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/priority/stale")
                .then(response => {
                    console.debug("getPriorityStaleJobs response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getPriorityStaleJobs error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getPriorityLeakedAssets() {
        console.debug("AdminFactory getPriorityLeakedAssets");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/priority/leaked")
                .then(response => {
                    console.debug("getPriorityLeakedAssets response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getPriorityLeakedAssets error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getLinkRemovalStats() {
        console.debug("AdminFactory getLinkRemovalStats");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/analytics/priority/removals/stats")
                .then(response => {
                    console.debug("getLinkRemovalStats response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getLinkRemovalStats error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getDetailedUserActivity() {
        console.debug("AdminFactory getDetailedUserActivity");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/admin/useractivity")
                .then(response => {
                    console.debug("getDetailedUserActivity response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getDetailedUserActivity error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }
};
