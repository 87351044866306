import axios from 'axios'
import globals from "@/globals";
import store from "@/store";

const axiosInstance = axios.create({
    baseURL: globals.host,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
})

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers["XSRF-TOKEN"] = store.getters.getCSRFToken;
    return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error === undefined || error.response === undefined) {
        console.debug("Unknown error occurred", error.response);
        return Promise.reject(error);
    }
    if (error.response.status === 401) {
        console.debug("axios.js: 401 error", error.response);
        store.commit("logoutNow");
    }
    return Promise.reject(error);
});

export default axiosInstance
