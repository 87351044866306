import axiosInstance from "@/axios";

export default {
    getHardExcludeData(lazyParams) {
        console.debug("FilterFactory getHardExcludeData")
        return new Promise((resolve, reject) => {
            axiosInstance.post("/settings/hardexclude", lazyParams)
                .then(response => {
                    console.debug("getHardExcludeData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getHardExcludeData error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    deleteHardExclude(domain) {
        console.debug("FilterFactory deleteHardExclude");
        let params = new URLSearchParams();
        params.append("domain", domain);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/settings/hardexclude?${params.toString()}`)
                .then(response => {
                    console.debug("deleteHardExclude response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteHardExclude error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSuspiciousDomainsData(lazyParams) {
        console.debug("FilterFactory getSuspiciousDomainsData")
        return new Promise((resolve, reject) => {
            axiosInstance.post("/settings/suspiciousdomains", lazyParams)
                .then(response => {
                    console.debug("getSuspiciousDomainsData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSuspiciousDomainsData error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    deleteSuspiciousDomain(domain) {
        console.debug("FilterFactory deleteSuspiciousDomain");
        let params = new URLSearchParams();
        params.append("domain", domain);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/settings/suspiciousdomains?${params.toString()}`)
                .then(response => {
                    console.debug("deleteSuspiciousDomain response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteSuspiciousDomain error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    createHardExclude(domain) {
        console.debug("FilterFactory createHardExclude");
        let params = new URLSearchParams();
        params.append("domain", domain);
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/settings/hardexclude/new?${params.toString()}`)
                .then(response => {
                    console.debug("createHardExclude response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("createHardExclude error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    createSuspiciousDomain(domain) {
        console.debug("FilterFactory createSuspiciousDomain");
        let params = new URLSearchParams();
        params.append("domain", domain);
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/settings/suspiciousdomains/new?${params.toString()}`)
                .then(response => {
                    console.debug("createSuspiciousDomain response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("createSuspiciousDomain error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getAssetSpecificFilters(sid) {
        console.debug("FilterFactory getAssetSpecificFilters");
        let params = new URLSearchParams();
        params.append("sid", sid);
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/settings/specific?${params.toString()}`)
                .then(response => {
                    console.debug("getAssetSpecificFilters response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAssetSpecificFilters error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },
}
