import axiosInstance from "@/axios";

export default {
    getSystemKeys() {
        console.debug("SettingsFactory getSystemKeys");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/settings/system")
                .then(response => {
                    console.debug("getSystemKeys response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSystemKeys error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSystemValues(key_id) {
        console.debug("SettingsFactory getSystemValues");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/settings/system/" + key_id)
                .then(response => {
                    console.debug("getSystemValues response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSystemValues error: ", error);
                    reject(error); // Reject with the error
                });
        })
    },

    updateSystemValue(key_id, value) {
        console.debug("SettingsFactory updateSystemValue");
        let params = new URLSearchParams();
        params.append("setting_value_key", key_id);
        params.append("setting_value", value);
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/settings/system/value?${params.toString()}`)
                .then(response => {
                    console.debug("updateSystemValue response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("updateSystemValue error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    newSystemValue(key_id, value) {
        console.debug("SettingsFactory newSystemValue");
        let params = new URLSearchParams();
        params.append("setting_key", key_id);
        params.append("setting_value", value);
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/settings/system/value?${params.toString()}`)
                .then(response => {
                    console.debug("newSystemValue response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("newSystemValue error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    deleteSystemValue(key_id) {
        console.debug("SettingsFactory deleteSystemValue");
        let params = new URLSearchParams();
        params.append("setting_value_id", key_id);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/settings/system/value?${params.toString()}`)
                .then(response => {
                    console.debug("deleteSystemValue response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteSystemValue error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

}
