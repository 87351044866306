import SearchPageFactory from "@/factories/SearchPageFactory";
import AdminFactory from "@/factories/AdminFactory";
import JobsFactory from "@/factories/JobsFactory";
import SettingsFactory from "@/factories/SettingsFactory";
import FilterFactory from "@/factories/FilterFactory";
import LinkRemovalFactory from "@/factories/LinkRemovalFactory";
import ClientFactory from "@/factories/ClientFactory";
import AssetFactory from "@/factories/AssetFactory";
import ScheduleFactory from "@/factories/ScheduleFactory";
import CyberlockerFactory from "@/factories/CyberlockerFactory";
import ManualFactory from "@/factories/ManualFactory";
import AnalyticsFactory from "@/factories/AnalyticsFactory";
import DatareviewFactory from "@/factories/DatareviewFactory";
import ExportFactory from "@/factories/ExportFactory";
import ProspectFactory from "@/factories/ProspectFactory";
import CaseFactory from "@/factories/CaseFactory";
import AuthFactory from "@/factories/AuthFactory";
import BlogFactory from "@/factories/BlogFactory";

const factories = {
    searchPage: SearchPageFactory,
    adminPage: AdminFactory,
    jobPage: JobsFactory,
    settings: SettingsFactory,
    filters: FilterFactory,
    linkRemoval: LinkRemovalFactory,
    client: ClientFactory,
    asset: AssetFactory,
    schedule: ScheduleFactory,
    cyberlocker: CyberlockerFactory,
    manual: ManualFactory,
    analytics: AnalyticsFactory,
    datareview: DatareviewFactory,
    export: ExportFactory,
    prospect: ProspectFactory,
    cases: CaseFactory,
    auth: AuthFactory,
    blog: BlogFactory,
}

export default factories;
