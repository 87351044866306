import axiosInstance from "@/axios";

export default {
    getLeakData() {
        console.debug("ProspectFactory getLeakData");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/prospect/leaktimeline")
                .then(response => {
                    console.debug("getLeakData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getLeakData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getClientLeakData() {
        console.debug("ProspectFactory getClientLeakData");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/prospect/leakbyclient")
                .then(response => {
                    console.debug("getClientLeakData response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getClientLeakData error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getLeakDetails() {
        console.debug("ProspectFactory getLeakDetails");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/prospect/leakeddetails")
                .then(response => {
                    console.debug("getLeakDetails response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getLeakDetails error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    deleteProspect(aid) {
        console.debug("ProspectFactory deleteProspect");
        let params = new URLSearchParams();
        params.append("aid", aid);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/prospect/single?${params.toString()}`)
                .then(response => {
                    console.debug("deleteProspect response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteProspect error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }
}
