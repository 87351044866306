import axiosInstance from "@/axios";

export default {
    getAllAssets() {
        console.debug("AssetFactory getAllAssets");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/asset/getall")
                .then(response => {
                    console.debug("getAllAssets response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAllAssets error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getAssetAllInfo(aid) {
        console.debug("AssetFactory getAssetAllInfo");
        const params = new URLSearchParams();
        params.append('aid', aid);
        return new Promise((resolve, reject) => {
            axiosInstance.get("/asset/info", { params: params })
                .then(response => {
                    console.debug("getAssetAllInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAssetAllInfo error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    updateAsset(assetInfo) {
        console.debug("AssetFactory updateAsset");
        return new Promise((resolve, reject) => {
            axiosInstance.put("/asset/info", assetInfo)
                .then(response => {
                    console.debug("updateAsset response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("updateAsset error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    createAsset(assetInfo) {
        console.debug("AssetFactory createAsset");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/asset/info", assetInfo)
                .then(response => {
                    console.debug("createAsset response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("createAsset error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getAllInfohashInfo() {
        console.debug("AssetFactory getAllInfohashInfo");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/asset/infohash/all")
                .then(response => {
                    console.debug("getAllInfohashInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAllInfohashInfo error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getAssetImages(assetId) {
        console.debug("AssetFactory getAssetImages")
        return new Promise((resolve, reject) => {
            axiosInstance.get("/asset/" + assetId + "/images")
                .then(response => {
                    console.debug("getAssetImages response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAssetImages error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    uploadAssetImages(assetId, images) {
        console.debug("AssetFactory uploadAssetImages")
        const formData = new FormData();
        images.files.forEach(file => {
            formData.append("images", file);
        })
        formData.append("asset_id", assetId);
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/asset/${assetId}/images`, formData,{
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(response => {
                    console.debug("uploadAssetImages response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("uploadAssetImages error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    deleteAssetImage(assetId, imageId) {
        console.debug("AssetFactory deleteAssetImage")
        let params = new URLSearchParams();
        params.append("image_id", imageId);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/asset/${assetId}/images?${params.toString()}`)
                .then(response => {
                    console.debug("deleteAssetImage response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteAssetImage error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getAssetLinkPreview(assetId) {
        console.debug("AssetFactory getAssetLinkPreview")
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/asset/linkpreview?aid=${assetId}`)
                .then(response => {
                    console.debug("getAssetLinkPreview response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAssetLinkPreview error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getAssetInfohashMetrics(assetId) {
        console.debug("AssetFactory getAssetInfohashMetrics")
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/asset/${assetId}/infohashmetrics`)
                .then(response => {
                    console.debug("getAssetInfohashMetrics response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAssetInfohashMetrics error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    insertInfohash(assetId, infohash) {
        console.debug("AssetFactory insertInfohash")
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/asset/${assetId}/infohash?hash=` + infohash)
                .then(response => {
                    console.debug("insertInfohash response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("insertInfohash error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }

}
