import axiosInstance from "@/axios";

export default {
    getProviders() {
        console.debug("LinkRemovalFactory getProviders")
        return new Promise((resolve, reject) => {
            axiosInstance.get("/linkremoval/providers")
                .then(response => {
                    console.debug("getProviders response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getProviders error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    updateProvider(provider) {
        console.debug("LinkRemovalFactory updateProvider");
        return new Promise((resolve, reject) => {
            axiosInstance.put("/linkremoval/providers", provider)
                .then(response => {
                    console.debug("updateProvider response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("updateProvider error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    addProvider(provider) {
        console.debug("LinkRemovalFactory addProvider");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/linkremoval/providers", provider)
                .then(response => {
                    console.debug("addProvider response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("addProvider error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    deleteProvider(provider_id) {
        console.debug("LinkRemovalFactory deleteProvider");
        let params = new URLSearchParams();
        params.append("provider_id", provider_id);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/linkremoval/providers?${params.toString()}`)
                .then(response => {
                    console.debug("deleteProvider response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteProvider error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    addTakedown(asset_id, links) {
        console.debug("LinkRemovalFactory addTakedown");
        let params = new URLSearchParams();
        params.append("asset_id", asset_id);
        params.append("links", links);
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/linkremoval/takedown/new`,{
                asset_id: asset_id,
                links: links
            })
                .then(response => {
                    console.debug("addTakedown response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("addTakedown error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getTakedowns(lazyParams) {
        console.debug("LinkRemovalFactory getTakedowns");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/linkremoval/takedown", lazyParams)
                .then(response => {
                    console.debug("getTakedowns response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getTakedowns error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    approveLink(link) {
        console.debug("LinkRemovalFactory approveLink");
        let params = new URLSearchParams();
        params.append("link", link);
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/linkremoval/takedown/approve?${params.toString()}`)
                .then(response => {
                    console.debug("approveLink response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("approveLink error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    removeLink(link) {
        console.debug("LinkRemovalFactory removeLink");
        let params = new URLSearchParams();
        params.append("link", link);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/linkremoval/takedown?${params.toString()}`)
                .then(response => {
                    console.debug("removeLink response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("removeLink error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    approveMultipleLinks(links) {
        console.debug("LinkRemovalFactory approveMultipleLinks");

        const params = new URLSearchParams();
        links.forEach(link => {
            params.append("link", link);
        });
        console.debug("params: ", params);
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/linkremoval/takedown/multi?${params.toString()}`)
                .then(response => {
                    console.debug("approveMultipleLinks response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("approveMultipleLinks error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    removeMultipleLinks(links) {
        console.debug("LinkRemovalFactory removeMultipleLinks");

        const params = new URLSearchParams();
        links.forEach(link => {
            params.append("link", link);
        });
        console.debug("params: ", params);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/linkremoval/takedown/multi?${params.toString()}`)
                .then(response => {
                    console.debug("removeMultipleLinks response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("removeMultipleLinks error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getStory(link_id) {
        console.debug("LinkRemovalFactory getStory");
        let params = new URLSearchParams();
        params.append("link_id", link_id);
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/linkremoval/link/story?${params.toString()}`)
                .then(response => {
                    console.debug("getStory response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getStory error: ", error);
                    reject(error); // Reject with the error
                });
        });
    }
}
