import axiosInstance from "@/axios";

export default {
    autoSubmitSearches(submissionObject) {
        console.debug("ExportFactory autoSubmitSearches");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/export/submit", submissionObject)
                .then(response => {
                    console.debug("autoSubmitSearches response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("autoSubmitSearches error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    exportAssetsToPDF(exportIds) {
        console.debug("ExportFactory exportAssetsToPDF");
        let body = {
            items: exportIds
        }
        return new Promise((resolve, reject) => {
            axiosInstance.post("/export/assets", body)
                .then(response => {
                    console.debug("exportAssetsToPDF response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("exportAssetsToPDF error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }
}
