import axiosInstance from "@/axios";

export default {
    createSchedule(scheduleData) {
        console.debug("ScheduleFactory createSchedule")
        return new Promise((resolve, reject) => {
            axiosInstance.post("/schedule/standard", scheduleData)
                .then(response => {
                    console.debug("createSchedule response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("createSchedule error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getSchedulesAndJobs() {
        console.debug("ScheduleFactory getSchedulesAndJobs")
        return new Promise((resolve, reject) => {
            axiosInstance.get("/schedule/info")
                .then(response => {
                    console.debug("getSchedulesAndJobs response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSchedulesAndJobs error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    deleteSchedule(scheduleId) {
        console.debug("ScheduleFactory deleteSchedule")
        let params = new URLSearchParams();
        params.append("schedule_id", scheduleId);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/schedule/remove/schedule?${params.toString()}`)
                .then(response => {
                    console.debug("deleteSchedule response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteSchedule error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    deleteScheduledJob(jobId) {
        console.debug("ScheduleFactory deleteScheduledJob")
        let params = new URLSearchParams();
        params.append("job_id", jobId);
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`/schedule/remove/job?${params.toString()}`)
                .then(response => {
                    console.debug("deleteScheduledJob response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("deleteScheduledJob error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }
}
