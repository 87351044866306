import { reactive } from "vue";
import { useCookies } from "vue3-cookies";

export default {
    cookies: useCookies(),

    data: reactive({
        loaded: false,
        theme: "lara-light-blue",
        language: "en",
        sidebar: {
            review: true,
            active: true,
            inactive: true,
            jobs: true,
            admin: true,
            settings: true
        }
    }),

    init(cookieContent) {
        this.data.theme = cookieContent.theme;
        this.data.language = cookieContent.language;
        this.data.sidebar.review = cookieContent.sidebar.review;
        this.data.sidebar.active = cookieContent.sidebar.active;
        this.data.sidebar.inactive = cookieContent.sidebar.inactive;
        this.data.sidebar.jobs = cookieContent.sidebar.jobs;
        this.data.sidebar.admin = cookieContent.sidebar.admin;
        this.data.sidebar.settings = cookieContent.sidebar.settings;

        this.loaded = true;
    }
}

